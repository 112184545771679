import React from "react";
import SEO from "../components/SEO";

// TODO: Implement a 404 page based on some design provided laters

const NotFoundPage = () => (
  <div>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn't exist... the sadness.</p>
  </div>
);

export default NotFoundPage;
